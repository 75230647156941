import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';

import Button from '../basic/Button';
import { Heading3, Type2 } from '../basic/typography';


const Wrapper = styled.div`
  margin: 50px auto;
  max-width: 1250px;
  text-align: center;
  padding: 50px 0px;
  ${media.xs`
    padding: 20px 0px;
  `}
  ${media.sm`
    padding: 30px 0px;
  `}
  ${media.md`
    padding: 30px 0px;
  `}
`;

const TextWrapper = styled.div`
  padding: 20px;
  text-align: left;
  display: block;
  width: 100%;
  ${media.xs`
  padding: 20px;
  display: block;
  width: 100%;
  `}
  ${media.sm`
  padding: 30px;
  display: block;
  width: 100%;
  `}
  ${media.md`
  padding: 40px;
  width: 60%;
  display: inline-block;
  `}
`;

const JourneyImg = styled.div`
display: block;
width: 100%;
vertical-align: top;
padding-top: 80px;
${media.xs`
display: block;
width: 100%;
`}
${media.sm`
display: block;
width: 100%;
`}
${media.md`
width: 40%;
display: inline-block;
`}
`;

const TextHeading = styled(Heading3)`
  text-align: left;
`;

const Par = styled(Type2)`
  text-align: left;
`;

const Text = ({title, content, file}) => (
  <TextWrapper>
    <TextHeading color='primary' weight='black'>
      {title}
    </TextHeading>
    {content.map((item,i)=>(
      <Par key={i} color='secondary'>{item}</Par>
    ))}
    { file && 
      <form method="get" target="_blank" action={file}>
        <Button type='submit'>Download</Button>
      </form>
    }
  </TextWrapper>
);

const StudiesItem = ({study, index, image}) => {
  
  console.log(image)
  let Windw = {innerWidth: 800};
  if (typeof window !== 'undefined') {
    Windw = window;
  }
  if (Windw.innerWidth < 720) {
    return (
      <Wrapper>
        <JourneyImg>
          <img src={image} alt={study.title} width='80%'/>
        </JourneyImg>
        <Text title={study.title} content={study.paragraphs} file={study.file} />
      </Wrapper>
    );
  }
  if (index%2 === 1) {
    return (
      <Wrapper>
        <JourneyImg>
          <img src={image} alt={study.title} width='80%'/>
        </JourneyImg>
        <Text title={study.title} content={study.paragraphs} file={study.file} />
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Text title={study.title} content={study.paragraphs} file={study.file} />
      <JourneyImg>
        <img src={image} alt={study.title} width='80%' />
      </JourneyImg>
    </Wrapper>
  );
};

export default StudiesItem;
