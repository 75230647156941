import React from 'react'
import { media, Row, Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import LazyHydrate from "react-lazy-hydration";

import { color } from '../basic/shared/styles';

import content from '../content';
import StudiesItem from '../components/StudiesItem';
import CS1 from '../images/casestudies/cs_avis_vr.png';
import CS2 from '../images/casestudies/cs_mrm.png';
import CS3 from '../images/casestudies/cs_sony.png';
import CS4 from '../images/casestudies/cs_lexus.png';

const images = [CS1, CS2, CS3, CS4];

const Wrapper = styled.div`
  padding-top: 100px;
`;

const RowWrap = styled(Row)`
  max-width: 1250px;
  margin: 0 auto;
`;

const ColWrap = styled(Col)`
  margin: 0 auto;
`;

const Studies = () => (
  <Wrapper>
    <RowWrap alignItems='center' justifyContent='center' noGutter >
    </RowWrap>
    {content.caseStudies.studies.map((study, i)=>(
      <RowWrap alignItems='center' justifyContent='center' noGutter >
        <StudiesItem
          key={i}
          index={i}
          study={study}
          image={images[i]}
        />
      </RowWrap>
    ))}
  </Wrapper>
);

export default Studies;
