import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Intercom from 'react-intercom';
import { BaseCSS } from 'styled-bootstrap-grid';
import { CookiesProvider, withCookies } from 'react-cookie';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Menu from '../components/Menu';
import CtaOne from '../layout/CtaOne';
import Contact from '../layout/Contact';
import Studies from '../layout/Studies';
import SocialProof from '../components/SocialProof';
import Cookies from '../components/Cookies';
import content from '../content';
import DividerTitle from '../layout/DividerTitle';

const IntercomR = (props) => (
  <Intercom appID="rhu8gzvn" mrm_cookie_id={props.cookies.get('mrm_cookie_id')} />
);
const IntercomC = withCookies(IntercomR);

const CaseStudiesPage = (props) => (
  <CookiesProvider>
      <StaticQuery
        query={graphql`
          query siteMetadataQuery2 {
            site {
              siteMetadata {
                title
                description
                author
                background_color
                theme_color
                keywords
                lang
                siteUrl
                metaImage {
                  image
                  height
                  width
                  alt
                }
              }
            }
          }
        `}
        render={data => {
          return (
          <>
            <BaseCSS />
            <Cookies page={props.location ? props.location.href : 'storybook'} />
            <Menu />
            <Layout>
              <SEO
                description={data.site.siteMetadata.description}
                title={'MRM Tech - Case Studies'}
                author={data.site.siteMetadata.author}
                lang={data.site.siteMetadata.lang}
                meta={data.site.siteMetadata.meta}
                keywords={data.site.siteMetadata.keywords}
                metaImage={data.site.siteMetadata.metaImage}
                siteUrl={data.site.siteMetadata.siteUrl}
              />
              <div style={{height: 180}}/>
              <DividerTitle 
                title={content.caseStudies.title}
                content={content.caseStudies.subTitle}
                height={470}
              />
              <div style={{height: 300}}/>
              <Studies />
              <CtaOne />
              <Contact />
            </Layout>
            <IntercomC  />
          </>
        ) }}
      />
  </CookiesProvider>
);

export default CaseStudiesPage
